@import "../../assets/styles/fonts";
@import "../../assets/styles/mainColors";
@import "../../assets/styles/mixins";

.vacancies {
  margin-top: 60px;
  background-color: #FBFCFD;

  .available-vacancies-wrapper {
    background-color: white;
    padding: 60px $mainPadding 120px;
    min-height: calc(100vh - 350px);
    margin-top: 120px;

    .available-vacancies-header {
      position: relative;

      .left-part {
        position: absolute;
        font-size: 2.5vw;
        font-family: Bold;
        color: #202020;
        height: 45px;
        display: flex;
        align-items: center;
      }

      .right-part {
        .ant-tabs {
          .ant-tabs-nav-container {
            display: flex;
            justify-content: flex-end;
          }

          .ant-tabs-bar {
            border-bottom: 0;
          }

          .ant-tabs-ink-bar {
            background-color: $blueColor;
            height: 3px;
          }

          .ant-tabs-content-animated {
            -webkit-transition: unset;
            transition: unset;
          }

          .ant-tabs-nav .ant-tabs-tab {
            padding: 12px 6px;
            @include font(Bold, 16);
            color: #202020;
          }

          .tab-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $borderGreyColor;
            padding: 20px 0;
            transition: .2s;
            cursor: pointer;

            &:first-child {
              margin-top: 20px;
            }

            .tab-content-title {
              width: calc(100% - 30px);
              @include font(Bold, 18);
              color: #202020;
              transition: .2s;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            svg {
              path {
                transition: .2s;
              }
            }

            &:hover {
              border-color: $blueColor;

              .tab-content-title {
                color: $blueColor;
              }

              svg {
                path {
                  fill: $blueColor;
                }
              }
            }
          }
        }
      }
    }
  }

}

@media all and (max-width: 1400px) {
  .vacancies {
    .slider-wrapper {
      padding: 50px 50px 0;
    }

    .vacancies-contact-wrapper {
      padding: 0 50px 100px;

      .left-part {
        width: 50%;
      }

      .right-part {
        width: 50%;
        padding: 60px 0 0 60px;
      }
    }

    .available-vacancies-wrapper {
      padding: 50px $mainPadding;
    }
  }
}

@media all and (max-width: 1200px) {
  .vacancies {
    .slider-wrapper {
      padding: 50px 50px 0;
    }

    .vacancies-contact-wrapper {
      padding: 0 50px 100px;
    }
  }
}

@media all and (max-width: 1000px) {
  .vacancies {
    .slider-wrapper {
      padding: 40px 40px 0;
    }

    .vacancies-contact-wrapper {
      padding: 0 40px 100px;
    }

    .available-vacancies-wrapper {
      padding: 40px $mainPadding;
    }

    .vacancies-contact-wrapper {
      flex-wrap: wrap;
      flex-direction: column-reverse;

      .left-part {
        width: 100%;
      }

      .right-part {
        width: 100%;
        padding-left: 0;
        padding-top: 0;
      }
    }

    .available-vacancies-wrapper {
      .available-vacancies-header {
        .left-part {
          position: unset;
          font-size: 5vw;
          margin-bottom: 30px;
        }

        .right-part {
          .ant-tabs {
            .ant-tabs-nav-container {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .vacancies {
    .slider-wrapper {
      padding: 0;

      .slider-main-content {
        top: -50px;
        justify-content: center;

        .slick-slider {
          width: calc(100% - 60px);
          margin-right: 0;
        }

        .arrows {
          right: 60px;
        }
      }
    }

    .slider-wrapper {
      padding: 30px 30px 0;
    }

    .vacancies-contact-wrapper {
      padding: 0 30px 50px;

      .right-part {
        .contact-title {
          font-size: 5.5vw;
        }

        .divider {
          margin: 20px 0 30px;
        }

        .contact-description {
          font-size: 15px;
          line-height: 22px;
        }

        .contact-btn {
          height: 40px;
          padding: 0 24px;
        }
      }
    }

    .available-vacancies-wrapper {
      padding: 20px $mainPadding;

      .available-vacancies-header {
        .left-part {
          font-size: 5.5vw;
          margin-bottom: 12px;
        }

        .right-part {
          .ant-tabs {
            .ant-tabs-nav-container {
              display: flex;
              justify-content: flex-end;
            }

            .ant-tabs-bar {
              border-bottom: 0;
            }

            .ant-tabs-ink-bar {
              background-color: $blueColor;
              height: 3px;
            }

            .ant-tabs-content-animated {
              -webkit-transition: unset;
              transition: unset;
            }

            .ant-tabs-nav .ant-tabs-tab {
              padding: 6px;
              @include font(Bold, 15);
              color: #202020;
            }

            .tab-content {
              padding: 12px 0;

              &:first-child {
                margin-top: 12px;
              }

              .tab-content-title {
                @include font(Bold, 15);
              }
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 550px) {
  .vacancies {
    .slider-wrapper {
      padding: 0;

      .slider-main-content {
        top: -50px;
        justify-content: center;

        .slick-slider {
          width: calc(100% - 60px);
          margin-right: 0;
          padding: 20px;

          .slider-item {
            .slide-title {
              @include font(Bold, 20);
              margin-bottom: 16px;
            }

            .slide-description {
              font-size: 15px;
              line-height: 22px;
            }
          }
        }

        .arrows {
          top: 16px;
          right: 40px;

          .slider-btn {
            width: 36px;
            height: 36px;

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }
}