:root {
  --brandColor: #146A80;
  --brandSecColor: #044D5E;
  --imgWidth: 490px;
  --imgHeight: 530px;
  --mainPadding: 270px;
}

$brandColor: var(--brandColor);
$brandSecColor: var(--brandSecColor);
$imgWidth: var(--imgWidth);
$imgHeight: var(--imgHeight);
$mainPadding: var(--mainPadding);
$borderColor: #CCCCCC;
$textColor: #2B2D33;

$badgeColor: #FF3939;
$confirmButtonColor: #2151A1;
$blueButtonColor: #0073F2;
$blueColor: #146A80;
$darkColor: #242424;

$inputBorderColor: #868686;
$inputTextColor: #1D1C1D;

$borderGreyColor: #e5e5e5;
$darkBlueColor: #10233C;

@media (max-width: 1700px) {
  :root {
    --mainPadding: 200px;
    --imgWidth: 400px;
    --imgHeight: 448px;
  }
}
@media (max-width: 1500px) {
  :root {
    --mainPadding: 160px;
  }
}
@media (max-width: 1300px) {
  :root {
    --mainPadding: 120px;
  }
}
@media (max-width: 1100px) {
  :root {
    --mainPadding: 100px;
  }
}
@media (max-width: 1050px) {
  :root {
    --mainPadding: 50px;
    --imgWidth: 320px;
    --imgHeight: 356px;
  }
}
@media (max-width: 750px) {
  :root {
    --mainPadding: 30px;
  }
}
@media (max-width: 500px) {
  :root {
    --mainPadding: 14px;
  }
}