@import "../fonts";
@import "../mainColors";

footer {
  width: 100%;
  background-color: #272B2C;
  position: relative;

  .footer {
    padding: 70px $mainPadding;
    border-bottom: 1px solid #525356;
    display: flex;
    justify-content: space-between;
    position: relative;

    &__content {
      max-width: 70%;
      width: calc(100% - 140px);
      display: flex;
      justify-content: space-between;
    }

    .footer-logo {
      width: 18%;
      max-width: 136px;
      max-height: 132px;
    }

    &__links {
      max-width: 18%;
      font-size: 13px;
      line-height: 20px;
      color: #A4A4A6;
      display: block;

      div{
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 6px;
        cursor: pointer;
      }
      a {
        font-size: 13px;
        line-height: 20px;
        color: #A4A4A6 !important;
        margin-bottom: 6px;
        display: block;
      }

      &__title {
        font-size: 16px;
        line-height: 22px;
        font-family: Medium;
        color: white;
        margin-bottom: 12px;

      }
    }

    .social-wrapper {

      &__title {
        font-size: 16px;
        line-height: 22px;
        font-family: Medium;
        color: white;
        margin-bottom: 12px;
      }

      .social-items {
        display: flex;

        .social-icon {
          cursor: pointer;
          width: 28px;
          height: 28px;
          background-repeat: no-repeat;
          background-size: cover;
          transition: all 0.2s;

          &:hover {
            path {
              fill: white;
            }
          }
        }
      }
    }

    .scroll-top{
      width: 56px;
      height: 56px;
      background-color: $brandColor;
      border-radius: 50%;
      position: absolute;
      right: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .copyright {
    padding: 15px $mainPadding;
    color: #A4A4A6;
  }

  @media all and (max-width: 1300px) {
    .footer {
      padding: 40px $mainPadding;
      .scroll-top{
        width: 44px;
        height: 44px;
        right: 40px;
        top: 40px;
        z-index: 1;
      }
      &__content {
        max-width: 80%;
      }

      .footer-logo {
        width: 18%;
        max-width: 106px;
        max-height: 102px;
      }
    }
  }

  @media all and (max-width: 1050px) {
    .footer {
      padding: 40px $mainPadding;
      flex-direction: column-reverse;

      &__content {
        width: 100%;
        max-width: 100%;

        .footer-logo {
          //display: none;
          position: absolute;
          top: 20px;
        }
      }

      .footer-logo {
        width: 25%;
      }

      &__links {
        font-size: 13px;
        line-height: 20px;
        color: #A4A4A6;
        max-width: 25%;

        a {
          font-size: 14px;
          line-height: 20px;
          color: #A4A4A6 !important;
          margin-bottom: 6px;
        }

        &__title {
          font-size: 16px;
          line-height: 22px;
          font-family: Medium;
          color: white;
          margin-bottom: 12px;
        }
      }

      .social-wrapper {
        margin-bottom: 30px;
        padding-left: 25%;

        &__title {
          font-size: 16px;
          line-height: 22px;
          font-family: Medium;
          color: white;
          margin-bottom: 12px;
        }

        .social-items {
          display: flex;

          .social-icon {
            cursor: pointer;
            width: 28px;
            height: 28px;
            background-repeat: no-repeat;
            background-size: cover;

            &:hover {
              path {
                fill: white;
              }
            }
          }
        }
      }

      .scroll-top{
        width: 44px;
        height: 44px;
        //right: calc(#{$mainPadding} + 40px);
        top: 40px;
        z-index: 1;
      }
    }
  }

  @media all and (max-width: 600px) {
    .footer {
      &__content {
        flex-wrap: wrap;
      }

      &__links {
        width: 50%;
        max-width: 50%;

        &__title {
          font-size: 16px;
          line-height: 22px;
          font-family: Medium;
          color: white;
          margin-bottom: 12px;
        }
      }

      .social-wrapper {
        padding-left: 50%;

        &__title {
          font-size: 16px;
          line-height: 22px;
          font-family: Medium;
          color: white;
          margin-bottom: 12px;
        }

        .social-items {
          display: flex;

          .social-icon {
            cursor: pointer;
            width: 28px;
            height: 28px;
            background-repeat: no-repeat;
            background-size: cover;

            &:hover {
              path {
                fill: white;
              }
            }
          }
        }
      }

      .scroll-top{
        width: 40px;
        height: 40px;
        right: 10px;
        svg{
          width: 22px;
        }
      }
    }
  }
}