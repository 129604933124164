@import "../../assets/styles/fonts";
@import "../../assets/styles/mainColors";
@import "../../assets/styles/mixins";

.vacancy-wrapper {
  margin-top: 60px;

  .vacancy-main-img-wrapper {
    padding: 40px 100px 0;

    .main-img {
      width: 100%;
    }
  }

  .vacancy-title-wrapper {
    padding: 40px 100px;

    .vacancy-title {
      font-size: 2.5rem;
      line-height: 50px;
      font-weight: bold;
      color: black;
      margin: 0 0 16px 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .conditions-wrapper {
      display: flex;
      flex-wrap: wrap;

      .condition-item {
        background-color: #F5F6F8;
        padding: 4px 8px;
        @include flex-center;
        margin: 8px 12px 0 0;
        border-radius: 4px;
        font-size: 16px;

        svg {
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
      }
    }
  }

  .vacancy-main-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 100px;

    .left-part {
      width: 50%;
      padding: 0 40px 40px 0;

      .left-part-section {
        margin-bottom: 50px;

        ul {
          list-style: inherit;
          padding-left: 20px;
        }

        .section-title {
          font-size: 1.5rem;
          font-family: Arial;
          font-weight: bold;
          line-height: 28px;
          color: black;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
          padding-left: 0;
        }

        .divider {
          border: 2px solid $blueColor;
          width: 12%;
          margin: 30px 0;
        }

        .section-description {
          font-size: 16px;
          line-height: 32px;
          text-align: left;
          padding-left: 0;
        }

        .skills-wrapper {
          display: flex;
          flex-wrap: wrap;

          .skill-item {
            border: 1px solid $borderColor;
            padding: 6px 16px;
            @include flex-center;
            margin: 12px 12px 0 0;
            border-radius: 4px;
            font-size: 16px;
          }
        }
      }
    }

    .right-part {
      width: 48%;
      padding: 32px;
      border-radius: 8px;
      border: 1px solid $borderColor;
      margin-bottom: 50px;
      position: sticky;
      top: 75px;

      .form-title {
        font-size: 1.125rem;
        line-height: 24px;
        color: black;
        font-weight: bold;
        margin: 0 0 20px 0;
      }

      .form-main-content {
        .required-field-badge {
          color: #ff0000;
        }

        .input-group {
          margin-bottom: 20px;

          &.phone {
            input {
              padding: 8px 12px 8px 64px;
            }

            .phone-code {
              position: absolute;
              bottom: 7px;
              left: 12px;
              font-size: 14px;
              border-right: 1px solid #bfbfbf;
              padding-right: 6px;
            }
          }
        }
      }

      .submit-btn {
        background-color: $blueColor;
        font-weight: 400;
        padding: 8px 24px;

        svg{
          margin-left: 6px;
        }


        .tick-icon {
          width: 0;
          height: 14px;
          transition: all 0.2s;
          visibility: visible;

          path {
            fill: white;
          }
        }

        .show-tick {
          width: 14px;
          visibility: visible;
          opacity: 1;
          margin-left: 10px;
        }

        .hide-tick {
          width: 0;
          visibility: hidden;
          opacity: 0;
          //width: 0;
        }
      }
    }
  }
}

@media all and (max-width: 1200px) {
  .vacancy-wrapper {

    .vacancy-main-img-wrapper {
      padding: 40px 60px 0;
    }

    .vacancy-title-wrapper {
      padding: 60px;
    }

    .vacancy-main-content {
      padding: 30px 60px;
    }
  }
}

@media all and (max-width: 1000px) {
  .vacancy-wrapper {
    .vacancy-main-img-wrapper {
      height: 300px;
      overflow: hidden;
      padding: 0;

      img {
        object-fit: cover;
        height: 100%;
      }
    }

    .vacancy-title-wrapper {
      padding: 40px;
    }

    .vacancy-main-content {
      padding: 20px 40px;
    }

    .vacancy-main-content {
      flex-wrap: wrap;

      .left-part {
        width: 100%;
        padding: 0;
      }

      .right-part {
        width: 100%;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .vacancy-wrapper {

    .vacancy-title-wrapper {
      padding: 30px 30px 0;

      .vacancy-title {
        font-size: 1.5rem;
      }
    }

    .vacancy-main-content {
      padding: 20px 30px;
    }

    .vacancy-main-content {

      .left-part {
        .left-part-section {
          margin-bottom: 30px;

          .section-title {
            font-size: 1.2rem;
          }

          .divider {
            margin: 20px 0;
          }
        }
      }
    }
  }
}

@media all and (max-width: 550px) {
  .vacancy-wrapper {

    .vacancy-title-wrapper {
      padding: 20px 20px 0;
    }

    .vacancy-main-content {
      padding: 20px;
    }

    .vacancy-main-content {

      .right-part {
        padding: 16px;
      }
    }
  }
}