@import "../../assets/styles/mainColors";
@import "../../assets/styles/mixins";

.no-result-empty-view {
  width: 100%;
  height: calc(100vh - 260px);
  //margin: 100px 0;
  @include flex-center;
  flex-direction: column;

  p {
    margin: 20px 0;
    color: black;
    @include font(Medium, 16);
  }

  @media all and (max-width: 500px) {
    margin: 50px 0;
    p {
      font-size: 14px;
    }
  }
}
