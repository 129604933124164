@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainColors";

.categories {
  width: 100%;

  .categories-list{
    width: 100%;
    padding: 70px calc(#{$mainPadding} - 30px) 70px $mainPadding;
  }

  .categories-item-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 80px;

    .categories-item {
      width: calc((100% / 3) - 30px);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      letter-spacing: 0;
      margin-bottom: 30px;
      margin-right: 30px;

      svg, img {
        width: 96px;
        height: 96px;
        margin-bottom: 20px;
        object-fit: contain;
      }

      .item-title {
        width: 100%;
        font-size: 28px;
        line-height: 36px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: Bold;
        color: $darkColor;
        margin-bottom: 15px;
      }

      .item-description {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        height: 72px;
        overflow: hidden;
        color: $darkColor;
      }
    }
  }

  @media all and (max-width: 1200px) {
    .categories-list {
      padding: 70px calc(#{$mainPadding} - 20px) 70px $mainPadding;
    }
    .categories-item-wrapper {

      .categories-item {
        width: calc((100% / 3) - 20px);
        margin-bottom: 20px;
        margin-right: 20px;

        .item-title {
          font-size: 24px;
          line-height: 30px;
        }

        .item-description {
          font-size: 14px;
          line-height: 22px;
          height: 64px;
        }
      }
    }
  }

  @media all and (max-width: 750px) {
    .categories-item-wrapper {
      .categories-item {
        width: calc(50% - 20px);
      }
    }
  }

  @media all and (max-width: 500px) {
    .categories-list {
      padding: 40px calc(#{$mainPadding} - 20px) 40px $mainPadding;
    }
    .categories-item-wrapper {

      .categories-item {
        width: calc(50% - 20px);
        margin-bottom: 20px;
        margin-right: 20px;

        .item-title {
          font-size: 20px;
          line-height: 20px;
        }

        .item-description {
          font-size: 13px;
          line-height: 18px;
          height: 52px;
        }
      }
    }
  }
}