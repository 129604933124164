@import "../mainColors";

.input-group {
  margin-bottom: 16px;
  position: relative;

  label {
    display: block;
    font-weight: 500;
    color: #000000;
    margin-bottom: 12px;
    .required-field-badge {
      color: #ff0000;
    }
  }

  input, textarea, select {
    font-size: 12px;
    font-weight: 400;
    height: 35px;
    width: 100%;
    padding: 6px 12px;
    border: 1px solid $borderColor;
    border-radius: 4px;
    color: $textColor;

    &::placeholder {
      color: #bababa;
    }

    &:focus {
      //outline: $brandColor auto 5px;
      outline: none;
      border: 1px solid darken($borderColor, 15%);
    }

    &.resizable {
      resize: vertical;
      min-height: 100px;
    }

    &.invalid {
      border: 1px solid #FF0000!important;
      outline: none;
    }
  }

  input[type="checkbox"] {
    height: 25px;
    width: 25px;
  }
  input[type="color"] {
    width: 200px;
  }

  textarea {
    resize: none;
    height: 125px;
  }

  .visibility-icon {
    position: absolute;
    bottom: 0px;
    right: 14px;
    height: 18px;
    text-align: center;
    cursor: pointer;
    display: block;
    transform: translateY(-50%);

    &.invisible i {
      color: $brandColor;
    }

    i {
      font-size: 15px;
      user-select: none;
      -webkit-transition: color 0.3s ease;
      transition: color 0.3s ease;
      color: #777;
    }
  }

  .ant-select-selection__placeholder {
    font-size: 12px;
    color: #bababa;
  }
}
.ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field{
  &:focus{
    border: none;
  }
}
.select-options {
  width: 100%;
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  z-index: 50;

  .option-item {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    overflow: hidden;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    -webkit-transition: background .3s ease;
    transition: background .3s ease;

    input {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }

    &:hover {
      background-color: #e6f7ff;
    }

    &.checked {
      color: rgba(0, 0, 0, 0.65);
      font-weight: 600;
      background-color: #fafafa;
    }
  }
}

@media screen and (max-width: 450px) {
  .input-group {
    margin-bottom: 10px;
  }
}
