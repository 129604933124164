@import "../fonts";
@import "../mainColors";

.front-layout-wrapper {
  .section-title {
    color: #2B2D33;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -2px;
    margin-bottom: 15px;
    margin-top: 30px;
    font-family: Bold;
    text-align: center;
  }

  .section-description {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.5px;
    color: $darkColor;
    text-align: center;
  }

}

@media all and (max-width: 1400px) {
  .front-layout-wrapper {

    .section-title {
      font-size: 42px;
    }

    .section-description {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

@media all and (max-width: 1200px) {
  .front-layout-wrapper {

    .section-title {
      font-size: 45px;
      line-height: 50px;
    }
  }
}

@media all and (max-width: 1000px) {
  .front-layout-wrapper {

    .section-title {
      font-size: 42px;
      line-height: 46px;
      margin-top: 20px;
    }

    .section-description {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

@media all and (max-width: 800px) {
  .front-layout-wrapper {
    .section-title {
      font-size: 38px;
      line-height: 40px;
      margin-bottom: 10px;
    }
    .section-description {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

@media all and (max-width: 600px) {
  .front-layout-wrapper {

    .section-title {
      font-size: 30px;
      line-height: 36px;
      padding: 0 20px;
    }

    .section-description {
      font-size: 12px;
      line-height: 16px;
      padding: 0 20px;
    }
}
}

@media all and (max-width: 400px) {
  .front-layout-wrapper {

    .section-title {
      font-size: 22px;
      line-height: 26px;
      padding: 0 20px;
    }
}
}