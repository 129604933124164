@import "../../assets/styles/fonts";
@import "../../assets/styles/mainColors";
@import "../../assets/styles/mixins";

.vacancies_info {

  .top_part {
    width: 100%;
    padding: 30px $mainPadding 70px $mainPadding;
    background-color: white;
    display: flex;
    justify-content: space-between;

    .content {
      width: 30%;

      .info_title {
        width: 100%;
        font-size: 48px;
        line-height: 60px;
        letter-spacing: -0.01em;
        color: $textColor;
        font-family: Bold;
        margin: 40px 0;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .info_paragraph {
        font-size: 23px;
        line-height: 34px;
        color: black;
      }
    }

    .info_img {
      width: 60%;
      object-fit: cover;
      transform: translateY(120px);
    }
  }

  .vacancies_paragraph {
    font-size: 23px;
    line-height: 34px;
    color: black;
    padding: 120px $mainPadding 70px $mainPadding;
  }

  .vacancies_description_wrapper {
    width: calc(100% - #{$mainPadding});
    padding: 120px $mainPadding 70px 100px;
    background-color: white;
    margin-left: auto;

    .vacancies_description{
      font-size: 23px;
      line-height: 34px;
      color: black;
    }

    .description_img {
      width: 100%;
    }
  }

  @media all and (max-width: 1400px) {
    .top_part {
      .content {
        width: 30%;

        .info_title {
          font-size: 40px;
          line-height: 44px;
          margin: 40px 0;
        }

        .info_paragraph {
          font-size: 20px;
          line-height: 30px;
        }
      }

      .info_img {
        width: 60%;
        object-fit: cover;
        transform: translateY(120px);
      }
    }

    .vacancies_paragraph {
      font-size: 20px;
      line-height: 30px;
    }

    .vacancies_description_wrapper {
      padding: 100px $mainPadding 70px 60px;

      .vacancies_description{
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  @media all and (max-width: 950px) {
    .top_part {
      flex-direction: column;
      .content {
        width: 100%;
      }

      .info_img {
        width: 100%;
        margin-top: 30px;
        transform: unset;
      }
    }

    .vacancies_paragraph {
      padding: 40px $mainPadding;
    }

    .vacancies_description_wrapper {
      width: 100%;
      padding: 60px $mainPadding;
    }
  }

  @media all and (max-width: 600px) {
    .top_part {
      .content {
        .info_title {
          font-size: 34px;
          line-height: 40px;
          margin: 20px 0;
        }

        .info_paragraph {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    .vacancies_paragraph {
      font-size: 16px;
      line-height: 20px;
    }

    .vacancies_description_wrapper {
      padding: 40px $mainPadding;

      .vacancies_description{
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

