@import "../../assets/styles/fonts";
@import "../../assets/styles/mainColors";

.projects_page {
  width: 100%;
  min-height: calc(100vh - 60px);
  padding: 60px $mainPadding;

  .page-title {
    font-size: 57px;
    line-height: 72px;
    letter-spacing: -0.01em;
    color: $darkColor;
    font-family: Bold;
    margin-bottom: 25px;
    margin-top: 122px;
  }

  .page-description {
    width: 100%;
    font-size: 20px;
    line-height: 28px;
    color: #202020;
    margin-bottom: 74px;
  }

  .projects-img-wrapper {
    width: 100%;
    position: relative;
    margin-bottom: 126px;

    .projects-img {
      width: 100%;
    }

    .project-links {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      padding: 20px 30px;

      .link {
        margin-right: 30px;
        font-size: 16px;
        color: white;
      }
    }
  }

  .block-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 70px;

    img {
      width: 45%;
    }

    .block-content {
      width: 45%;

      .block-title {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 20px;
        font-family: Bold;
        color: #2B2D33;
      }
    }

    &:nth-child(odd) {
      flex-direction: row-reverse;

      .block-content {

      }
    }

    &:nth-child(even) {
      .block-content {
        padding-left: 60px;

        .block-title {
          text-align: center;
        }
      }
    }
  }

  .tools-wrapper {
    display: flex;

    .tools-item {
      margin-right: 12%;

      .tool-title {
        //width: 30%;
        font-size: 28px;
        line-height: 36px;
        color: black;
        padding-left: 24px;
        margin-bottom: 20px;
      }

      .tag {
        position: relative;
        padding-left: 24px;
        font-size: 23px;
        line-height: 34px;

        &:after {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background-color: var(--brandColor);
        }
      }
    }
  }

  @media all and (max-width: 700px) {
    .page-title {
      font-size: 40px;
      line-height: 52px;
      margin-bottom: 20px;
      margin-top: 25px;
    }

    .page-description {
      width: 100%;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 20px;
    }
    .projects-img-wrapper {
      margin-bottom: 60px;
    }

    .block-wrapper {
      flex-wrap: wrap;
      flex-direction: column;

      img {
        width: 100%;
      }

      .block-content {
        width: 100%;

        .block-title {
          font-size: 24px;
          line-height: 30px;
          margin-top: 20px;
        }
      }

      &:nth-child(odd) {
        flex-direction: column-reverse;

        .block-content {

        }
      }

      &:nth-child(even) {
        flex-direction: column-reverse;

        .block-content {
          padding-left: 0;

          .block-title {
            text-align: start;
          }
        }
      }
    }

    .tools-wrapper {
      flex-wrap: wrap;

      .tools-item {
        margin-right: 12%;

        .tool-title {
          font-size: 17px;
          line-height: 26px;
          padding-left: 16px;
          margin-bottom: 10px;
        }

        .tag {
          padding-left: 16px;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}