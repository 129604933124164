@import "../../assets/styles/fonts";
@import "../../assets/styles/mainColors";

.service-details {
  width: 100%;
  min-height: calc(100vh - 60px);
  padding: 60px 0 60px $mainPadding;
  background-color: #F5F5F5;

  .page-title {
    font-size: 57px;
    line-height: 72px;
    letter-spacing: -0.01em;
    color: $darkColor;
    font-family: Bold;
    margin-bottom: 25px;
    margin-top: 122px;
  }

  .page-description {
    width: 60%;
    font-size: 20px;
    line-height: 28px;
    color: #202020;
    margin-bottom: 122px;
  }

  .services-content {

    .services-list {
      padding: 58px 100px;
      background-color: white;
      overflow: auto;
      white-space: nowrap;
      min-width: 600px;

      .list-item {
        margin-right: 48px;
        font-size: 16px;
        line-height: 22px;
        color: $darkColor;
        transition: all 0.2s;

        &:hover {
          color: $brandColor;
        }
      }

      &::-webkit-scrollbar {
        height: 0;
        cursor: pointer;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        cursor: pointer;
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
        cursor: pointer;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: transparent;
        cursor: pointer;
      }
    }

    .services-content-list {
      .service {
        background-color: white;
        padding: 58px 100px;
        margin-bottom: 100px;

        &__header {
          display: flex;
          align-items: baseline;
          justify-content: space-between;

          .title {
            font-size: 40px;
            line-height: 50px;
            letter-spacing: -0.01em;
            color: $darkColor;
            width: 33%;
            font-family: Bold;
          }

          .description {
            width: 60%;
            font-weight: 500;
            font-size: 22px;
            line-height: 32px;
            color: black;
          }
        }


        &__blocks {
          width: 100%;
          margin-top: 25px;

          &__images {
            display: flex;
            justify-content: space-between;
            margin: 40px -30px;

            .block_img_wrapper {

              .inner {
                position: relative;
                padding-top: 100%;

                img {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  padding: 30px;
                  object-fit: cover;
                }
              }

              &.full {
                .inner {
                  padding-top: 36.6%;
                }
              }
            }

          }

          &__description {
            width: 60%;
            margin-left: auto;
          }
        }
      }
    }
  }

  @media all and (max-width: 1050px) {
    .services-content {

      .services-list {
        padding: 30px $mainPadding;
        min-width: 100%;
        margin-left: calc(1px - #{$mainPadding});
      }

      .services-content-list {
        margin-left: calc(1px - #{$mainPadding});

        .service {
          padding: 30px $mainPadding;
          overflow: hidden;

          &__header {
            flex-wrap: wrap;

            .title {
              width: 100%;
              margin-bottom: 20px;
            }

            .description {
              width: 100%;
            }
          }

          &__blocks {
            &__description {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 600px) {
    .page-title {
      font-size: 40px;
      line-height: 52px;
      margin-bottom: 20px;
      margin-top: 25px;
    }

    .page-description {
      width: 100%;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 25px;
    }

    .services-content {

      .services-list {
        padding: 10px $mainPadding;
      }

      .services-content-list {

        .service {
          padding: 30px $mainPadding;

          &__header {
            .title {
              font-size: 30px;
              line-height: 32px;
            }

            .description {
              width: 100%;
              font-size: 18px;
              line-height: 24px;
            }
          }

          &__blocks {
            margin-top: 25px;

            &__images {
              margin: 20px -10px;

              .block_img_wrapper {
                .inner {
                  img {
                    padding: 10px;
                  }
                }
              }
            }

            &__description {
              width: 100%;
            }
          }
        }
      }
    }
  }
}