@import "../../assets/styles/fonts";
@import "../../assets/styles/mainColors";

.contacts_page {
  min-height: 100vh;
  padding-top: 20px;
  background-color: #FBFCFD;
  overflow: hidden;
  transform: translateY(40px);

  &__content {
    padding: 0 0 0 $mainPadding;
    display: flex;
    justify-content: space-between;

    .info {
      width: 55%;
      display: flex;
      align-items: center;

      div {
        width: 50%;
        display: flex;
        flex-direction: column;

        h1 {
          font-size: 60px;
          line-height: 76px;
          font-family: Bold;
        }

        a {
          font-size: 28px;
          line-height: 36px;
          font-family: Medium;
          margin-bottom: 20px;
          transition: all 0.2s;
          color: #2B2D33;

          &:hover {
            color: $brandColor;
          }

          &:first-child {
            color: $brandColor;
            margin: 15px 0 20px;;

            &:hover {
              color: #2B2D33;
            }
          }
        }
      }
    }

    .form {
      width: 42%;
      padding: 90px 60px;
      border-radius: 8px 0 0 8px;
      background-color: white;
      transform: translateY(100px);

      .send-btn{
        padding: 6px 26px;
        font-size: 16px;
      }
    }
  }

  &__address {
    height: 170px;
    padding: 0 $mainPadding;
    background-color: #272B2C;
    color: white;
    font-size: 19px;
    line-height: 28px;
    display: flex;
    align-items: center;
  }

  &__map {
    width: 100%;
    height: 60vh;
  }

  @media all and (max-width: 1300px) {
    &__content {
      .info {
        div {
          h1 {
            font-size: 48px;
            line-height: 62px;
          }

          a {
            font-size: 22px;
            line-height: 30px;
            margin-bottom: 14px;
          }
        }
      }

      .form {
        width: 42%;
        padding: 60px 40px;
      }
    }
  }

  @media all and (max-width: 1050px) {
    &__content {
      .info {
        width: 46%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        div {
          width: 100%;
          h1 {
            font-size: 48px;
            line-height: 62px;
          }

          a {
            font-size: 22px;
            line-height: 30px;
            margin-bottom: 14px;
          }
        }
      }

      .form {
        width: 50%;
        padding: 60px 40px;
      }
    }
  }

  @media all and (max-width: 700px) {
    padding-top: 80px;
    &__content {
      flex-direction: column;
      .info {
        width: 100%;
        div {
          width: 100%;
        }
      }

      .form {
        width: 100%;
        padding: 40px 20px;
        transform: unset;
        border-radius: 8px 0 0 0;
      }
    }
  }
}

[class*="ground-pane"] {
  filter: grayscale(1) brightness(0.3)
}