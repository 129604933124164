@import "../mainColors";

.link-button {
  font-size: 15px;
  color: #fff;
  border-radius: 5px;
  padding: 6px 16px;
  border: none;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  background: $brandColor;

  &.bg-orange {
    background: orange;
  }
  &.bg-red {
    background: #FF4E41;
  }
  &.bg-grey {
    background: #AEB5BB;
  }
  &.bg-green {
    background: #0ACC84;
  }
  &.bg-white {
    background: white;
    border: 1px solid #ccc;
    color: black;
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &:disabled {
    color: #fff;
    background: #AEB5BB;
    opacity: .65;
    cursor: default;
  }
  &.unchangeable {
    color: #fff;
    background: #AEB5BB;
    opacity: .65;
    cursor: default;
    &:hover {
      opacity: .65;

    }
  }
  &:hover {
    background: $brandSecColor;
  }
  .flex-row {
    display: flex;
    align-items: center;
    .ant-spin-dot {
      height: 18px;
    }
    .ant-spin {
     // margin-left: 8px;
    }
  }
  .title {
    display: flex;
    justify-content: center;
    i {
      font-size: 18px;
      padding: 3px 0;
    }
  }
}
