@import "../mainColors";
@import "../fonts";
@import "../mixins";

.post-image-uploader {
  //overflow: hidden;
  position: relative;
  background-color: white;
  margin-top: 15px;
  height: 125px;

  .image-wrapper {
    width: 100%;
    height: 125px;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity, border-image .2s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
    border-style: solid;
    border-width: 4px 4px 4px 4px;
    -moz-border-image: url('../../images/front/vacancies/file-border-active.svg') 5 5 5 5 repeat repeat;
    -webkit-border-image: url('../../images/front/vacancies/file-border-active.svg') 5 5 5 5 repeat repeat;
    -o-border-image: url('../../images/front/vacancies/file-border-active.svg') 5 5 5 5 repeat repeat;
    border-image: url('../../images/front/vacancies/file-border-active.svg') 5 5 5 5 repeat repeat;

    .upload-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .box-title {
        color: black;
        display: flex;
        align-items: center;
        @include font(Regular, 16);
        margin-bottom: 12px;

        .file-icon {
          margin-right: 8px;
        }
        .clear-icon {
          margin-left: 8px;
          transition: .2s;
          width: 16px;
          height: 16px;
          &:hover {
            transform: scale(1.3);
          }
        }

        span {
          color: $brandColor;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .box-text {
        color: $borderColor;
        margin-bottom: 0;
        @include font(Regular, 16);
      }
    }
  }

  .crop-img-selector {
    width: 100%;
    height: 125px;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity, border-image .2s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
    border-style: solid;
    border-width: 4px 4px 4px 4px;
    -moz-border-image: url('../../images/front/vacancies/file-border.svg') 5 5 5 5 repeat repeat;
    -webkit-border-image: url('../../images/front/vacancies/file-border.svg') 5 5 5 5 repeat repeat;
    -o-border-image: url('../../images/front/vacancies/file-border.svg') 5 5 5 5 repeat repeat;
    border-image: url('../../images/front/vacancies/file-border.svg') 5 5 5 5 repeat repeat;

    &.invalid {
      -moz-border-image: url('../../images/front/vacancies/file-border-invalid.svg') 5 5 5 5 repeat repeat;
      -webkit-border-image: url('../../images/front/vacancies/file-border-invalid.svg') 5 5 5 5 repeat repeat;
      -o-border-image: url('../../images/front/vacancies/file-border-invalid.svg') 5 5 5 5 repeat repeat;
      border-image: url('../../images/front/vacancies/file-border-invalid.svg') 5 5 5 5 repeat repeat;
    }

    .upload-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .box-title {
        color: black;
        @include font(Regular, 16);
        margin-bottom: 12px;

        span {
          color: $brandColor;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .box-text {
        color: $borderColor;
        margin-bottom: 0;
        @include font(Regular, 16);
      }
    }

    .error-wrapper {
      color: red;
      margin-top: 15px;
    }


    &:hover {
      -moz-border-image: url('../../images/front/vacancies/file-border-active.svg') 5 5 5 5 repeat repeat;
      -webkit-border-image: url('../../images/front/vacancies/file-border-active.svg') 5 5 5 5 repeat repeat;
      -o-border-image: url('../../images/front/vacancies/file-border-active.svg') 5 5 5 5 repeat repeat;
      border-image: url('../../images/front/vacancies/file-border-active.svg') 5 5 5 5 repeat repeat;
      opacity: 1;
    }
  }

  input {
    display: none;
  }
}

//@media all and (max-width: 1600px) {
//  .post-image-uploader {
//    .crop-wrapper {
//      //height: 350px;
//
//      .cropper {
//        //height: 350px !important;
//      }
//    }
//
//    .cropped-images-wrapper {
//
//      .crop-img-content {
//        width: 90px;
//      }
//    }
//
//    .crop-img-selector {
//      height: 350px;
//    }
//  }
//}
//
//@media all and (max-width: 1000px) {
//  .post-image-uploader {
//    .crop-wrapper {
//      height: 400px;
//
//      .cropper {
//        height: 400px !important;
//      }
//    }
//
//    .cropped-images-wrapper {
//
//      .crop-img-content {
//        width: 100px;
//      }
//    }
//
//    .crop-img-selector {
//      height: 400px;
//    }
//  }
//}
//
//@media all and (max-width: 600px) {
//  .post-image-uploader {
//    .crop-wrapper {
//      height: 350px;
//
//      .cropper {
//        height: 350px !important;
//      }
//    }
//
//    .crop-img-selector {
//      height: 350px;
//    }
//  }
//}
//
//@media all and (max-width: 500px) {
//  .post-image-uploader {
//    .crop-wrapper {
//      height: 300px;
//
//      .zoom-slider {
//        height: 220px;
//      }
//
//      .cropper {
//        height: 300px !important;
//      }
//    }
//
//    .crop-img-selector {
//      height: 300px;
//    }
//  }
//}
//
//@media all and (max-width: 500px) {
//  .post-image-uploader {
//    .crop-wrapper {
//      height: 252px;
//
//      .cropper {
//        height: 250px !important;
//      }
//    }
//
//    .crop-img-selector {
//      height: 250px;
//    }
//  }
//}
