@import "../../assets/styles/fonts";
@import "../../assets/styles/mainColors";
@import "../../assets/styles/mixins";

.about_page {
  margin-top: 60px;
  background-color: #FBFCFD;

  .top_wrapper {
    background-color: white;
    padding: 60px $mainPadding;
  }

  &__title {
    color: $darkColor;
    font-size: 50px;
    line-height: 64px;
    font-family: Bold;
    margin-bottom: 40px;
  }

  &__quantity_wrapper {
    display: flex;
    justify-content: space-between;

    .quantity_item {
      color: $textColor;
      width: 23%;
      .title {
        font-size: 36px;
        line-height: 48px;
        font-family: Bold;
        margin-bottom: 10px;
      }

      .desc {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  .paragraph{
    padding: 80px $mainPadding;
    color: black;
    font-size: 20px;
    line-height: 30px;
  }

  &__description_wrapper {
    width: calc(100% - #{$mainPadding});
    padding: 120px $mainPadding 70px 100px;
    background-color: white;
    margin-left: auto;

    .vacancies_description{
      font-size: 23px;
      line-height: 34px;
      color: black;
    }

    .description_img {
      width: 100%;
    }
  }

  &__description_wrapper {
    width: calc(100% - #{$mainPadding});
    padding: 120px $mainPadding 70px 100px;
    background-color: white;
    margin-left: auto;
    margin-bottom: 100px;

    .vacancies_description{
      font-size: 23px;
      line-height: 34px;
      color: black;
    }

    .description_img {
      width: 100%;
    }
  }

  &__members_wrapper {
    width: calc(100% - #{$mainPadding});
    padding: 120px $mainPadding 70px 100px;
    background-color: white;
    margin-left: auto;
    margin-bottom: 100px;

    .members_title{
      font-size: 42px;
      line-height: 50px;
      margin-bottom: 60px;
      font-family: Bold;
      color: black;
    }

    .members_top_list{
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      .member_item{
        width: 32%;
        position: relative;

        &__img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &__content{
          position: absolute;
          bottom: 20px;
          left: 20px;
          z-index: 1;
          color: white;
          .title{
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 5px;
            font-family: Medium;
          }
          .desc{
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }

    .members_bottom_list{
      display: flex;
      justify-content: space-between;
      .member_img{
        width: 23%;
      }
    }
  }

  @media all and (max-width: 1050px) {
    &__title {
      font-size: 34px;
      line-height: 50px;
      margin-bottom: 30px;
    }

    &__quantity_wrapper {
      .quantity_item {
        .title {
          font-size: 28px;
          line-height: 36px;
        }

        .desc {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .paragraph{
      padding: 60px $mainPadding;
      font-size: 16px;
      line-height: 22px;
    }

    &__members_wrapper {
      padding: 60px $mainPadding 40px 100px;
      background-color: white;
      margin-left: auto;
      margin-bottom: 100px;

      .members_title{
        font-size: 30px;
        line-height: 42px;
      }
    }
  }
  @media all and (max-width: 650px){
    &__title {
      font-size: 26px;
      line-height: 32px;
      margin-bottom: 22px;
    }

    &__quantity_wrapper {
      flex-wrap: wrap;

      .quantity_item {
        width: 46%;
        margin-bottom: 20px;

        .title {
          font-size: 26px;
          line-height: 36px;
          margin-bottom: 10px;
        }

        //.desc {
        //  font-size: 18px;
        //  line-height: 22px;
        //}
      }
    }

    &__members_wrapper {
      padding: 40px $mainPadding;
      margin-bottom: 60px;

      .members_title{
        font-size: 26px;
        line-height: 30px;
        margin-bottom: 20px;
      }

      .members_top_list{

        .member_item{

          &__content{
            bottom: 10px;
            left: 10px;

            .title{
              font-size: 14px;
              line-height: 18px;
              margin-bottom: 3px;
            }
            .desc{
              font-size: 11px;
              line-height: 16px;
            }
          }
        }
      }

      .members_bottom_list{
        flex-wrap: wrap;
        .member_img{
          width: 48%;
          margin-bottom: 20px;
        }
      }
    }
  }
}