@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainColors";

.main {
  width: 100%;

  &__slider-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #F5F5F5;
    position: relative;
    padding: 60px $mainPadding 0 $mainPadding;

    .content {
      width: calc(90% - #{$imgWidth});
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 95px 0;

      .text-content {
        .main-title {
          color: black;
          margin-bottom: 15px;
          font-family: Bold;
          font-size: 57px;
          line-height: 72px;
          height: 144px;
          letter-spacing: -1px;
          overflow: hidden;

          .title {
            font-family: Bold;
            max-width: 300px;
            overflow: hidden;
            word-break: break-word;
          }

          .blue-text {
            background-color: $blueColor;
            color: white;
            //margin-left: 10px;
            font-family: Bold;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 6px;
          }
        }

        .main-description {
          font-size: 19px;
          line-height: 28px;
          font-weight: 500;
          color: $darkColor;
        }
      }

      .link {
        .blue-text {
          color: $brandColor;
          font-size: 20px;
          margin-right: 25px;
        }

        .link-btn {
          padding: 10px 52px;
          background-color: white;
          color: $brandColor;
          border: 1px solid transparent;
          outline: 1px solid $brandColor;
          border-radius: 6px;
          transition: all 0.2s;
          overflow: hidden;

          &:hover{
            border: 1px solid $brandColor;
          }
        }
      }
    }

    .main-img {
      width: $imgWidth;
      height: $imgHeight;
      position: relative;
      object-fit: cover;
      clip-path: polygon(100px 0, 0 100%, 100% 100%, 100% 0);
    }
  }

  &__paragraph {
    width: 100%;
    padding: 70px $mainPadding 0 $mainPadding;
    color: $darkColor;

    &__content {
      border-left: 14px solid $brandColor;
      padding-left: 40px;
    }

    &__title {
      width: 70%;
      font-size: 46px;
      line-height: 60px;
      font-family: Bold;
      margin-bottom: 40px;
    }

    &__description {
      width: 70%;
      font-size: 20px;
      line-height: 34px;

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }

  @media all and (max-width: 1450px) {
    &__slider-wrapper {

      .content {
        width: calc(95% - #{$imgWidth});
        padding: 70px 0;

        .text-content {
          .main-title {
            margin-bottom: 15px;
            font-size: 52px;
            line-height: 66px;
            height: 130px;
          }
        }
      }
    }

    &__paragraph {
      padding: 60px $mainPadding 0 $mainPadding;

      &__content {
        border-left: 14px solid $brandColor;
        padding-left: 40px;
      }

      &__title {
        width: 70%;
        font-size: 40px;
        line-height: 52px;
        margin-bottom: 30px;
      }

      &__description {
        width: 70%;
        font-size: 18px;
        line-height: 26px;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }

  @media all and (max-width: 1250px) {
    &__slider-wrapper {

      .content {
        width: calc(95% - #{$imgWidth});
        padding: 60px 0;

        .text-content {
          .main-title {
            font-size: 44px;
            line-height: 60px;
            height: 122px;
          }

          .main-description {
            font-size: 16px;
            line-height: 28px;
          }
        }

        .link {
          .blue-text {
            font-size: 18px;
            margin-right: 20px;
          }

          .link-btn {
            padding: 10px 52px;
            background-color: white;
            color: $brandColor;
            border: 1px solid $brandColor;
            border-radius: 6px;
          }
        }
      }
    }

    &__paragraph {

      &__title {
        width: 80%;
      }

      &__description {
        width: 80%;
      }
    }
  }

  @media all and (max-width: 1050px) {
    &__slider-wrapper {

      .content {
        padding: 60px 0;

        .text-content {
          .main-title {
            font-size: 36px;
            line-height: 48px;
            height: 94px;
          }

          .main-description {
            font-size: 14px;
            line-height: 20px;
          }
        }

        .link {
          .blue-text {
            font-size: 16px;
            margin-right: 20px;
          }

          .link-btn {
            padding: 8px 38px;
          }
        }
      }

      .main-img {
        clip-path: polygon(70px 0, 0 100%, 100% 100%, 100% 0);
      }
    }

    &__paragraph {

      &__title {
        width: 100%;
      }

      &__description {
        width: 100%;
      }
    }
  }

  @media all and (max-width: 750px) {
    &__slider-wrapper {
      flex-direction: column;
      padding: 60px 0 0 0;

      .content {
        width: 100%;
        padding: 25px $mainPadding;
        .link {
          margin-top: 25px;
        }
      }

      .main-img {
        width: 100%;
        clip-path: polygon(70px 0, 0 100%, 100% 100%, 100% 0);
        //clip-path: unset;
      }
    }

    &__paragraph {

      &__title {
        font-size: 34px;
        line-height: 45px;
        margin-bottom: 26px;
      }

      &__description {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  @media all and (max-width: 450px) {
    &__slider-wrapper {

      .content {

        .text-content {
          .main-title {
            font-size: 30px;
            line-height: 44px;
            height: 84px;
          }

          .main-description {
            font-size: 14px;
            line-height: 20px;
          }
        }

        .link {
          .blue-text {
            font-size: 14px;
          }
        }
      }

      .main-img {
        clip-path: unset;
      }
    }

    &__paragraph {

      &__title {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 25px;
      }

      &__description {
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
}