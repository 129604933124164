@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainColors";

.ranges {
  width: 100%;
  padding: 50px $mainPadding 70px $mainPadding;
  background-color: #F5F5F5;
  overflow: hidden;

  .ranges-item-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 75px;
    background: $brandSecColor;
    border-radius: 12px;
    padding: 40px 12%;

    .ranges-item {
      width:20%;
      text-align: center;
      letter-spacing: 0;
      color: white;

      &__percent {
        width: 100%;
        font-size: 40px;
        line-height: 52px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: Bold;
      }

      &__text {
        font-size: 14px;
        line-height: 20px;
        height: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 10px;
      }

      &__title {
        width: 100%;
        font-size: 23px;
        line-height: 34px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: Bold;
        margin-bottom: 0;
      }
    }
  }

  &__contact{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    .blue-text{
      color: $darkColor;
      font-size: 20px;
      line-height: 20px;
      margin-right: 26px;
      white-space: nowrap;
    }
    &__button{
      color: $brandColor;
      background-color: white;
      outline: 1px solid $brandColor;
      border: 2px solid transparent;
      padding: 10px 52px;
      border-radius: 6px;
      white-space: nowrap;
      font-size: 16px;
      line-height: 22px;
      transition: all 0.2s;
      overflow: hidden;

      &:hover{
        border: 2px solid $brandColor;
      }
    }
  }

  @media all and (max-width: 1500px) {
    .ranges-item-wrapper {
      padding: 40px 8%;
    }
  }

  @media all and (max-width: 1050px) {
    .ranges-item-wrapper {
      padding: 20px 6% 40px 6%;
      .ranges-item {
        width: 20%;
        text-align: center;
        letter-spacing: 0;
        color: white;

        &__percent {
          font-size: 30px;
          line-height: 40px;
          height: 40px;
        }

        &__text {
          font-size: 12px;
          line-height: 16px;
          height: 16px;
          margin-bottom: 5px;
        }

        &__title {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }

  @media all and (max-width: 750px) {
    padding: 20px 0 40px 0;
    .ranges-item-wrapper {
      padding: 40px $mainPadding;
      flex-wrap: wrap;
      border-radius: 0;
      margin-top: 56px;

      .ranges-item {
        width: calc(100% / 3);
        margin-bottom: 30px;

        &:nth-child(4), &:last-child{
          margin-bottom: 0;
        }

        &__percent {
          font-size: 26px;
          line-height: 36px;
          height: 36px;
        }

        &__text {
          font-size: 11px;
          line-height: 14px;
          height: 14px;
          margin-bottom: 5px;
        }

        &__title {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }

    &__contact{
      .blue-text{
        font-size: 12px;
        line-height: 12px;
        margin-right: 10px;
      }
      &__button{
        padding: 8px 16px;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }

}