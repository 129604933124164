@font-face {
  font-family: Bold;
  src: url(../fonts/SF-Pro-Text-Bold.otf);
}
@font-face {
  font-family: Medium;
  src: url(../fonts/SF-Pro-Text-Medium.otf);
}
@font-face {
  font-family: Regular;
  src: url(../fonts/SF-Pro-Text-Regular.otf);
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/SF-Pro-Text-RobotoBlack.ttf);
}
@font-face {
  font-family: Semibold;
  src: url(../fonts/SF-Pro-Text-Semibold.otf);
}
@font-face {
  font-family: Light;
  src: url(../fonts/SF-Pro-Text-Light.otf);
}