@import "../../assets/styles/fonts";
@import "../../assets/styles/mainColors";

.projects_page {
  width: 100%;
  min-height: calc(100vh - 60px);
  padding: 60px $mainPadding;

  .page-title {
    font-size: 57px;
    line-height: 72px;
    letter-spacing: -0.01em;
    color: $darkColor;
    font-family: Bold;
    margin-bottom: 25px;
    margin-top: 122px;
  }

  .page-description {
    width: 60%;
    font-size: 20px;
    line-height: 28px;
    color: #202020;
    margin-bottom: 74px;
  }

  .project-types-list {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 100px;
    font-size: 23px;
    line-height: 34px;

    .list-item {
      margin-left: 25px;
      cursor: pointer;
      &.selected{
        color: $brandColor;
      }
    }
  }

  .projects-content {
    .project {
      display: flex;
      justify-content: space-between;
      margin-bottom: 120px;

      &__content {
        width: 46%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
          font-size: 28px;
          line-height: 36px;
          font-family: Bold;
          color: $darkColor;
          margin-bottom: 20px;
        }

        .description {
          font-size: 16px;
          line-height: 22px;
        }

        .read_more {
          font-size: 19px;
          line-height: 28px;
          letter-spacing: 0.006em;
          color: black;
          margin-top: 30px;
          display: flex;
          align-items: center;

          svg {
            transform: rotate(90deg);
            height: 18px;
            width: 18px;
            margin-left: 5px;
            transition: all 0.2s;

            path {
              fill: black;
            }
          }

          &:hover {
            svg {
              margin-left: 8px;
            }
          }
        }

      }

      &__img {
        width: 46%;
        position: relative;

        .inner {
          width: 100%;
          position: relative;
          padding-top: 73.3%;

          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
          }
        }

        .features-wrapper {
          width: 60%;
          overflow: hidden;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          position: absolute;
          bottom: 18px;
          left: 30px;
          z-index: 1;
          color: white;

          .feature-item {
            overflow: hidden;
            display: inline-block;
            font-size: 14px;
            margin: 0 10px 0 0;

            &:not(:last-child) {
              &::after {
                content: ", ";
              }
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1050px) {
    .projects-content {
      .project {
        margin-bottom: 80px;

        &__content {
          width: 46%;

          .title {
            font-size: 22px;
            line-height: 30px;
            margin-bottom: 16px;
          }

          .description {
            font-size: 15px;
            line-height: 20px;
          }

          .read_more {
            font-size: 14px;
            line-height: 18px;
            margin-top: 10px;
          }
        }

        &__img {
          width: 46%;

          .features-wrapper {
            .feature-item {
              font-size: 14px;
              line-height: 16px;
              margin: 0 10px 0 0;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 700px) {
    .page-title {
      font-size: 40px;
      line-height: 52px;
      margin-bottom: 20px;
      margin-top: 25px;
    }

    .page-description {
      width: 100%;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 40px;
    }

    .project-types-list {
      justify-content: flex-start;
      margin-bottom: 50px;
      font-size: 18px;
      line-height: 30px;

      .list-item {
        margin-left: 0;
        margin-right: 20px;
      }
    }

    .projects-content {
      .project {
        flex-direction: column-reverse;
        margin-bottom: 80px;

        &__content {
          width: 100%;

          .title {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 12px;
          }

          .description {
            font-size: 14px;
            line-height: 18px;
          }

          .read_more {
            font-size: 13px;
            line-height: 16px;
            margin-top: 10px;
          }
        }

        &__img {
          width: 100%;
          margin-bottom: 30px;

          .features-wrapper {
            bottom: 10px;
            left: 16px;

            .feature-item {
              font-size: 12px;
              line-height: 16px;
              margin: 0 6px 0 0;
            }
          }
        }
      }
    }
  }
}