@import "../fonts";
@import "../mainColors";

.navigation-wrapper {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;


  .nav-header {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 0 $mainPadding;

    .logo {
      position: relative;
      width: 100px;
      height: 30px;
    }

    .nav-header-right {
      display: flex;
      align-items: center;

      .nav-header-item {
        margin-right: 20px;
        font-size: 16px;
        font-weight: bold;
        color: black;
        display: flex;
        align-items: center;

        .nav-header-item-badge {
          position: relative;
          top: -12px;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background-color: #06AB6A;
          font-size: 12px;
          color: white;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            position: relative;
            left: -1px;
          }
        }
      }
    }

    .menu-btn {
      //background-color: transparent;
      display: flex;
      align-items: flex-end;
      font-size: 16px;
      position: relative;
      height: 30px;
      min-width: 28px;
      vertical-align: bottom;
      padding-left: 28px;
      background: none;
      color: #000;
      //font-weight: bold;
      z-index: 101;

      .btn-text {
        font-family: Bold;
        font-size: 20px;
      }

      .menu-icon {
        margin-right: 10px;
      }

      .humb-icon {
        position: absolute;
        top: 50%;
        left: 0;
        height: 16px;
        width: 16px;
        margin-top: -8px;
        overflow: hidden;
        transition: .5s ease-in-out;
        cursor: pointer;

        span {
          position: absolute;
          left: 0;
          height: 3px;
          width: 100%;
          background: #000;

          &:first-child {
            top: 0;
            transition: -webkit-transform .4s ease .15s;
            transition: transform .4s ease .15s;
            transition: transform .4s ease .15s, -webkit-transform .4s ease .15s;

            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: -200%;
              height: 3px;
              width: 100%;
              background: #000;
              transition: opacity .4s ease .1s, -webkit-transform .4s ease;
              transition: transform .4s ease, opacity .4s ease .1s;
              transition: transform .4s ease, opacity .4s ease .1s, -webkit-transform .4s ease;
            }
          }

          &:nth-child(2) {
            top: 6px;
            transition: opacity .4s ease, -webkit-transform .4s ease 75ms;
            transition: transform .4s ease 75ms, opacity .4s ease;
            transition: transform .4s ease 75ms, opacity .4s ease, -webkit-transform .4s ease 75ms;

            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: -200%;
              height: 3px;
              width: 100%;
              background: #000;
              transition: -webkit-transform .4s ease;
              transition: transform .4s ease;
              transition: transform .4s ease, -webkit-transform .4s ease;
            }
          }

          &:nth-child(3) {
            top: 6px;
            opacity: 0;
            transition: opacity 20ms .4s, -webkit-transform .4s ease;
            transition: transform .4s ease, opacity 20ms .4s;
            transition: transform .4s ease, opacity 20ms .4s, -webkit-transform .4s ease;
          }

          &:nth-child(4) {
            top: 12px;
            transition: -webkit-transform .4s ease;
            transition: transform .4s ease;
            transition: transform .4s ease, -webkit-transform .4s ease;

            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: -200%;
              height: 3px;
              width: 100%;
              background: #000;
              transition: opacity .4s ease .1s, -webkit-transform .4s ease;
              transition: transform .4s ease, opacity .4s ease .1s;
              transition: transform .4s ease, opacity .4s ease .1s, -webkit-transform .4s ease;
            }
          }
        }
      }

      &:hover .humb-icon {
        span {
          &:first-child, &:nth-child(2), &:nth-child(4) {
            -webkit-transform: translateX(200%);
            transform: translateX(200%);
          }
        }
      }
    }
  }

  .nav-dropdown {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 60px;
    //pointer-events: none;
    opacity: 0;
    background: #000;
    visibility: hidden;
    height: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    transition: opacity .3s ease, -webkit-transform .3s ease;
    transition: opacity .3s ease, transform .3s ease;
    transition: opacity .3s ease, transform .3s ease, -webkit-transform .3s ease;
    z-index: 100;
    animation: showEffect 1s;

    .nav-menu, & .nav-products {
      position: relative;
      opacity: 0;
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      transition: opacity .3s ease, -webkit-transform .3s ease;
      transition: opacity .3s ease, transform .3s ease;
      transition: opacity .3s ease, transform .3s ease, -webkit-transform .3s ease;
      z-index: 2;
    }

    .nav-menu {
      visibility: hidden;

      .menu {
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        background: #323232;
        color: white;
        height: 60px;
        font-weight: 600;

        .menu-item {
          margin-right: 50px;
          font-size: 18px;
          cursor: pointer;

          a {
            color: white !important;
          }
        }
      }
    }

    .nav-products {
      visibility: hidden;
      height: 200px;
      overflow: hidden;
      padding: 52px 100px;

      .react-multi-carousel-track {
        //width: calc(100% - 100px) !important;
        //width: 100%;
        .react-multi-carousel-item {

          &:last-child {
            width: 80px !important;
          }

          .menu-slider-item {
            width: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .item-content {
              text-align: center;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;

              .item-img {
                height: 65px;
                width: 65px;
                object-fit: contain;
                border-radius: 8px;
              }

              .item-text {
                width: 100%;
                color: white;
                font-size: 14px;
                margin-top: 10px;
                font-family: Medium;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }

  @keyframes showEffect {
    to {
      visibility: hidden;
    }
    from {
      visibility: visible;
    }
  }

  &.-open .nav-dropdown, &.-open .nav-menu, &.-open .nav-products {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

}

.-open {
  .nav-dropdown {
    visibility: visible;
    //height: 334px;
    height: 120px;

    .nav-menu, .nav-products {
      visibility: visible;
    }
  }

  .nav-header {

    .logo {
      z-index: 101;
    }

    .menu-btn {
      color: white;
      z-index: 101;

      .btn-text {
        margin-top: -2px;
        font-family: Bold;
        font-size: 18px;
      }

      .humb-icon {
        color: #fff;
        //width: 14px;
        span {
          color: #fff;
          background: #fff;
          //z-index: 1;
          &:before {
            background: #fff;
          }
        }
      }

      .humb-icon span:nth-child(2):before {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        background: #fff;
        height: 2px;
      }

      .humb-icon span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        opacity: 1;
        height: 2px;
        transition: -webkit-transform .4s ease;
        transition: transform .4s ease;
        transition: transform .4s ease, -webkit-transform .4s ease;
      }

      .humb-icon span:first-child,
      .humb-icon span:nth-child(2),
      .humb-icon span:nth-child(4) {
        -webkit-transform: translateX(200%);
        transform: translateX(200%);
      }
    }
  }

  .nav-menu {
    transition: opacity .3s ease .1s, -webkit-transform .3s ease .1s;
    transition: opacity .3s ease .1s, transform .3s ease .1s;
    transition: opacity .3s ease .1s, transform .3s ease .1s, -webkit-transform .3s ease .1s
  }
}

@media all and (max-width: 1000px) {
  .navigation-wrapper {
    .nav-header {
      padding: 0 40px;
    }

    .nav-dropdown {

      .nav-menu, & .nav-products {
        position: relative;
        opacity: 0;
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        transition: opacity .3s ease, -webkit-transform .3s ease;
        transition: opacity .3s ease, transform .3s ease;
        transition: opacity .3s ease, transform .3s ease, -webkit-transform .3s ease;
        z-index: 2;
      }

      .nav-menu {
        .menu {
          .menu-item {
            margin-right: 20px;
            font-size: 16px;
          }
        }
      }

      .nav-products {
        height: 200px;
        overflow: hidden;
        padding: 40px;

        .react-multi-carousel-track {
          .react-multi-carousel-item {
            .menu-slider-item {
              .item-content {
                .item-img {
                  width: 56px;
                  height: 56px;
                }

                .item-text {
                  font-size: 14px;
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }

    &.-open .nav-dropdown, &.-open .nav-menu, &.-open .nav-products {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
}

@media all and (max-width: 700px) {
  .navigation-wrapper {

    .nav-dropdown {
      .nav-products {
        height: 180px;
        overflow: hidden;
        padding: 50px 20px;

        .react-multi-carousel-track {

          .react-multi-carousel-item {

            &:last-child {
              width: unset !important;
            }

            .menu-slider-item {
              width: 60px;

              .item-content {
                .item-img {
                  width: 46px;
                  height: 46px;
                }

                .item-text {
                  font-size: 12px;
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }

    &.-open .nav-dropdown, &.-open .nav-menu, &.-open .nav-products {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
}

@media all and (max-width: 600px) {
  .navigation-wrapper {
    .nav-header {
      padding: 0 20px;
    }

    .nav-dropdown {

      .nav-menu {

        .menu {
          .menu-item {
            margin-right: 10px;
            font-size: 14px;
          }
        }
      }

      .nav-products {
        height: 180px;
        overflow: hidden;
        padding: 50px 20px;

        .menu-slider-item {
          .item-img {
            width: 46px;
            height: 46px;
          }

          .item-text {
            font-size: 13px;
            margin-top: 10px;
          }
        }
      }
    }

    &.-open .nav-dropdown, &.-open .nav-menu, &.-open .nav-products {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
}

@media all and (max-width: 450px) {
  .navigation-wrapper {

    .nav-dropdown {
      .nav-products {
        .react-multi-carousel-track {
          .react-multi-carousel-item {

            &:last-child {
            }

            .menu-slider-item {
              .item-content {

                .item-img {
                  width: 42px;
                  height: 42px;
                }
              }
            }
          }
        }
      }
    }

    &.-open .nav-dropdown, &.-open .nav-menu, &.-open .nav-products {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
}