@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainColors";

.services {
  width: 100%;
  padding: 40px calc(#{$mainPadding} - 30px) 40px $mainPadding;

  .services-item-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;

    .services-item {
      width: calc((100% / 3) - 30px);
      margin-right: 30px;
      margin-bottom: 30px;
      border: 1px solid #D9E4E7;
      border-radius: 12px;
      padding: 28px 20px 28px 20px;
      position: relative;
      cursor: pointer;
      transition: all 0.2s;

      &__title {
        font-size: 20px;
        line-height: 34px;
        font-family: Bold;
        color: black;
        margin-bottom: 0;
        height: 64px;
        overflow: hidden;
        padding-right: 80px;
      }

      &__description {
        font-size: 14px;
        line-height: 18px;
        height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 500;
        color: $darkColor;
        margin-bottom: 10px;
      }

      &__button {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F4F7F8;
        position: absolute;
        bottom: 28px;
        right: 20px;
        transition: all 0.2s;
      }
      &:hover{
        border-color: $brandColor;
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);

        .services-item__button {
          background-color: $brandColor;
         svg{
           path{
             fill: white;
           }
         }
        }
      }
    }
  }
    &__contact{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      overflow: hidden;
      .contact-text{
        color: $darkColor;
        font-size: 20px;
        line-height: 20px;
        margin-right: 26px;
        white-space: nowrap;
      }
      &__button{
        color: white;
        background-color: $brandColor;
        padding: 10px 52px;
        border-radius: 6px;
        white-space: nowrap;
        font-size: 16px;
        line-height: 22px;
        transition: all 0.2s;

        &:hover{
          background-color: $brandSecColor;
        }
      }
    }

  @media all and (max-width: 1200px) {
    padding: 40px calc(#{$mainPadding} - 20px) 40px $mainPadding;
    .services-item-wrapper {

      .services-item {
        width: calc((100% / 3) - 20px);
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media all and (max-width: 1050px) {
    .services-item-wrapper {
      .services-item {
        padding: 18px 66px 18px 14px;

        &__title {
          font-size: 16px;
          line-height: 22px;
          height: 42px;
        }

        &__description {
          font-size: 13px;
          line-height: 18px;
          height: 18px;
        }

        &__button {
          width: 34px;
          height: 34px;
          bottom: 18px;
          right: 14px;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  @media all and (max-width: 750px) {
    .services-item-wrapper {
      .services-item {
        width: calc(50% - 20px);
      }
    }
  }

  @media all and (max-width: 500px) {
    padding: 40px calc(#{$mainPadding} - 10px) 40px $mainPadding;
    .services-item-wrapper {
      .services-item {
        width: calc(50% - 10px);
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 14px 46px 14px 10px;

        &__title {
          font-size: 13px;
          line-height: 18px;
          height: 34px;
        }

        &__description {
          font-size: 12px;
          line-height: 16px;
          height: 16px;
          margin-bottom: 5px;
        }

        &__button {
          width: 24px;
          height: 24px;
          bottom: 14px;
          right: 10px;

          svg {
            width: 14px;
            height: 14px;
          }
        }
      }
    }

    &__contact{
      margin-top: 30px;
      .contact-text{
        font-size: 14px;
        line-height: 22px;
        margin-right: 14px;
      }
      &__button{
        font-size: 13px;
        line-height: 18px;
        padding: 10px 30px;
        border-radius: 6px;
      }
    }
  }
}