@import "../fonts";
@import "../mainColors";

.contact {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;

  .contact-title {
    color: black;
    font-size: 50px;
    font-family: Bold;
    letter-spacing: 1px;
    margin-bottom: 80px;
  }

  .contact-content {
    width: 50%;
    margin-top: 75px;

    .success-message {
      margin-bottom: 20px;
    }

    .input-group {
      label {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 5px;
        color: $darkColor;
      }

      input, select {
        border: 1px solid #979797;
        height: 50px;

        &::placeholder {
          color: #979797;
          font-size: 15px;
        }
      }

      textarea {
        border: 1px solid #979797;

        &::placeholder {
          color: #979797;
          font-size: 15px;
        }
      }
    }

    .ant-select {
      .ant-select-selection--single {
        height: 50px;
        border: 1px solid #979797;
        font-size: 15px;

        .ant-select-selection__rendered {
          margin-top: 7px;
        }

        .ant-select-selection__placeholder {
          font-size: 15px;
          color: #979797;
          //top: calc(50% + 10px);
        }
      }
    }

    .btn-wrapper {
      display: flex;
      justify-content: flex-end;

      .send-btn {
        height: 50px;
        width: 100%;
      }
    }

    .contact-info {
      width: 100%;
      margin-top: 30px;

      .contact-description {
        color: $darkColor;
      }

      div {
        height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

@media all and (max-width: 1400px) {
}

@media all and (max-width: 1200px) {
  .contact {
    .contact-title {
      font-size: 35px;
    }
  }
}

@media all and (max-width: 1000px) {
  .contact {
    .contact-title {
      font-size: 35px;
    }

    .contact-content {
      width: 70%;
    }
  }
}

@media all and (max-width: 800px) {
  .contact {
    .contact-title {
      font-size: 25px;
    }

    .contact-content {
      width: 80%;
    }
  }
}

@media all and (max-width: 600px) {
  .contact {
    .contact-title {
      font-size: 20px;
      padding: 0 20px;
    }

    .contact-content {
      width: 100%;
      padding: 0 20px;
    }
  }
}